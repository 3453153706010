import React from "react"
import Layout from "../../components/layout/layout"
import SEO from "../../components/seo"
import logo from "../../assets/css/images/bloglogo.png"
import BlogSidebar from "../../components/blog-sidebar"
import { articles } from "../blog"

export default () => (
  <Layout isHome={false} pageId="blogpage" logo={logo}>
    <SEO title="The Language of Modular Design" />
    <div id="content">
      <ul id="content_article">
        <li
          id="post-115"
          className="post-115 post type-post status-publish format-standard hentry category-blog"
        >
          <h1>The Language of Modular Design</h1>
          <p className="postdetails">
            Posted on <span>August 11, 2015</span>
          </p>
          <div className="wrap_blog_text">
            <div className="contact-intro">
              As many of us move away from designing pages toward designing
              systems, one concept keeps cropping up: modularity. We often hear
              about the benefits of a modular approach; modules are scalable,
              replaceable, reusable, easy to test, quick to put
              together—“They’re just like LEGO!”
            </div>
            <p>
              Modularity might appear to be a simple concept at first, but
              making it work for your team demands significant effort and
              commitment.
            </p>
            <p>
              The biggest challenges around modularity are all the decisions
              that need to be reached: when to reuse a module and when to design
              a new one, how to make modules distinct enough, how to combine
              them, how to avoid duplications with the modules other designers
              and teams create, and so on. When modularizing an existing design
              or building a new one, it’s not always clear where to begin.
            </p>
            <h3>Start with language</h3>
            <p>
              Language is fundamental to collaboration. In her book How to Make
              Sense of Any Mess, Abby Covert says that the biggest obstacle
              teams face is the lack of a shared language. To help establish
              that shared language, she suggests that we discuss, vet, and
              document our ontological decisions in the form of “controlled
              vocabularies.”
            </p>
            <p>
              One of our first experiments with modularity was an attempt to
              redesign our homepage. A visual designer created modular slices,
              and we then held a workshop where we tried to organize the modules
              into comps. That’s what we thought (perhaps naively) a “modular
              design process” would look like.
            </p>
            <p>
              Ultimately, we decided not to use the resulting designs. These
              experiments were useful in propelling us into a modular mindset,
              but the defining step toward thinking modularly was going through
              the process of building a pattern library as a team, which took
              several months (and is still in progress).
            </p>{" "}
            <h3>Name things collaboratively</h3>
            <p>
              Once you lay the foundation, it’s important to build on it by
              evolving the language as a team. An important part of that is
              naming the things you create.{" "}
            </p>
            <p>
              Imagine you have a simple component whose function is to persuade
              people to take a specific online course. What would you call it?
            </p>
            <p>
              The name depends on the component’s function and how and where it
              appears in the interface. Some people, for example, might call it
              “image header” or “course promo.”
            </p>
          </div>
        </li>
      </ul>

      <BlogSidebar articles={articles} />
    </div>
  </Layout>
)
